@import url(https://fonts.googleapis.com/css?family=Montserrat:100i,200,400,900,900i);
:root {
  --darkgrey-link:#838282;
  --lightgrey-border: #f1f1f1;
  --darkgrey-title: #333;
  --footer-height: 60px;
}

body{
  font-family: 'Montserrat', sans-serif !important;
  font-size:14px !important;
  font-weight: 400 !important;
  -webkit-font-smoothing: antialiased;
}

.page-title {
  font-size:calc(100% + 2vw);
  font-weight:200;
}

.page-title p {
  margin-bottom: 0;
}

.page-description {
  font-size:18px;
  color:#838282;
  color:var(--darkgrey-link);
  margin-top:30px;
}

a {
  text-decoration:none;
}


a:hover {
  text-decoration: none !important;
}

.main-container {
  margin-bottom: 60px;
  margin-bottom: var(--footer-height);
}

.divider-bottom {
  border-bottom:2px solid #f1f1f1;
  border-bottom:2px solid var(--lightgrey-border);
}

.divider-top {
  border-top:2px solid #f1f1f1;
  border-top:2px solid var(--lightgrey-border);
  margin-top: 10px;
  padding-top: 10px;
  height: 60px;
  height: var(--footer-height);
}

#language-button {
  color: #838282;
  color: var(--darkgrey-link);
  background-color: #f1f1f1;
  background-color: var(--lightgrey-border);
  border-color: #f1f1f1;
  border-color: var(--lightgrey-border);
}

.submit-btn {
  width: 220px;
  height: 40px;
  background: #f1f1f1 !important;
  background: var(--lightgrey-border) !important;
  color: #333 !important;
  color: var(--darkgrey-title) !important;
  border: 0 !important;
  cursor: pointer;
  float: right;
  font-size: 20px;
}

.submit-btn:hover {
  border:1px solid #838282 !important;
  border:1px solid var(--darkgrey-link) !important;
}

.row-btn {
  width: 100%;
}

.both-link {
  display: flex;
  justify-content: space-between;
}

.right-link {
  display: flex;
  justify-content: flex-end;
}

.right-link a, .grid-item a, .both-link a {
  color: #838282 !important;
  color: var(--darkgrey-link) !important;
	transition: all 0.3s ease-in;
}


.right-link a:hover {
  border-bottom:1px solid #838282;
  border-bottom:1px solid var(--darkgrey-link);
  color: #333 !important;
  color: var(--darkgrey-title) !important;
}

#multiselect_delete_project {
  height: 500px;
}

.social-icon {
  margin-left: 1px;
  margin-right: 1px;
}

/***** LOADING *****/

.preloader{
  position:fixed;
  top:0;
  width:100%;
  height:100%;
  background:white;
  z-index:9999;
}

.loader {
  display: inline-block;
  position:absolute;
  margin-left:-25px;
  margin-top:-25px;
  width: 60px;
  height: 60px;
  border: 2px solid #888;
  border-radius: 10%;
  opacity: 0.2;
  left: 50%;
  top: 50%;
  animation: loader 2s infinite ease;
}

.loader-inner {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  background-color: #888;
  border-radius: 10%;
  animation: loader-inner 2s infinite ease-in;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-inner {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}

/***** PROTFOLIO GRID *****/

.react-photo-gallery--gallery img {
  padding: 10px;
  margin: 0 !important;
  border-radius: 25px;
}


.react-photo-gallery--gallery {
  margin: 30px auto;
}

.grid-item {
  cursor: "pointer";
  overflow: "hidden";
  position: "relative";
}

.grid-hover{
  position: absolute;
  width:100%;
  height: 100%;
  top:0;
  background-color: white;
  border-radius: 25px;
  z-index: 2;
  opacity: 0;
	transition: all 0.3s ease-in;
}

.grid-hover:hover, .grid-over:active{
  opacity: 0.80;
  border-radius: 25px;
}

.grid-hover .svg-title {
  bottom:80px;
  left:20px;
  position:absolute;
  color: #333;
  color: var(--darkgrey-title);
  letter-spacing:1px;
}

.grid-hover p {
  font-size:16px;
  bottom:30px;
  left:20px;
  position:absolute;
  color:#838282;
  color:var(--darkgrey-link);
  line-height: 1;
} 

/***** IMAGE GALLERY (PROJECT PAGE) *****/

.image-gallery, .image-gallery-using-mouse {
  margin-top: 30px;
}

.image-gallery-thumbnail.active, .image-gallery-thumbnail:hover, .image-gallery-thumbnail:focus {
  border: 2px solid #838282 !important;
  border: 2px solid var(--darkgrey-link) !important;
}

.image-gallery-icon:hover {
  color: #333;
  color: var(--darkgrey-title);
}

/***** TABLE *****/

.expandable-row {
  cursor: pointer;
}

.caret {
  color: #838282;
  color: var(--darkgrey-link);
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup .caret {
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid\9;
  margin: 10px 0;
  
}

.dropdown .caret {
  margin: 10px 0 10px 5px;
}

.table td {
  vertical-align: middle !important;
}

/***** BOOTSTRAP *****/

.nav-link {
  color: #838282;
  color: var(--darkgrey-link);
}

.nav-link:hover {
  color: #333 !important;
  color: var(--darkgrey-title) !important;
}

.admin-page .nav-link.active{
  color: #333 !important;
  color: var(--darkgrey-title) !important;
  background-color: #f1f1f1 !important;
  background-color: var(--lightgrey-border) !important;
}

.admin-page .nav-link:hover{
  color: #333 !important;
  color: var(--darkgrey-title) !important;
}

.navbar-toggler {
  font-size: inherit !important;
}

.fixed-bottom {
  padding-bottom: 10px;
  background-color: #f1f1f1;
  background-color: var(--lightgrey-border);
  max-width: inherit !important;
}
.text-danger {
  font-size: 15px;
}

.form-control {
  background-color: #f1f1f1 !important;
  background-color: var(--lightgrey-border) !important;
  border: 1px solid #f1f1f1 !important;
  border: 1px solid var(--lightgrey-border) !important;
  background-clip: inherit !important;
  border-radius: inherit !important;
  transition: inherit !important;
}

.form-control:focus {
  border-color: #f1f1f1 !important;
  border-color: var(--lightgrey-border) !important;
  background-color: white !important;
  box-shadow: inherit !important;
}

.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .05rem rgba(131, 130, 130,.5) !important;
}

.card-header {
  background-color: #f1f1f1 !important;
  background-color: var(--lightgrey-border) !important;
}
